.red > td {
    background-color: #f6e7e7 !important;
}

.blue > td {
    background-color: #e7ecf6 !important;
}

.red:hover > td,
.blue:hover > td {
    border-bottom-width: 2px;
    border-bottom-color: #1890ff;
}
